<template>
   <AmcText
      :text="text"
      class="status mx-auto"
      :class="{ alarm: Alarm, online: Online, offline: Offline, fault: Fault, normal: Normal }"
   >
      <AmcText class="status-text">
         {{ text }}
      </AmcText>
   </AmcText>
</template>

<script>
export default {
   name: 'AmcStatusIcon',
   props: {
      Alarm: {
         type: Boolean,
         default: false,
      },
      Online: {
         type: Boolean,
         default: false,
      },
      Offline: {
         type: Boolean,
         default: false,
      },
      Fault: {
         type: Boolean,
         default: false,
      },
      Normal: {
         type: Boolean,
         default: false,
      },
      text: {
         type: [String, Number],
         default: null,
      },
   },
};
</script>

<style scoped lang="scss">
.status {
   width: 70px;
   display: flex;
   justify-content: center;
   padding-top: 3px;
   padding-bottom: 3px;
   border-radius: 50px;
   & .status-text {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 500;
   }
}
.alarm {
   background-color: #ea5348;
   color: white;
}
.offline {
   background-color: transparent;
   border: 1px solid #a8a8a8;
   color: #a8a8a8;
}
.online {
   background-color: #2fa84f;
   color: white;
}
.fault {
   background-color: #f5bd17;
   color: white;
}
.normal {
   background-color: #a8a8a8;
   color: white;
}
</style>
